import { Inject, Injectable } from "@angular/core";
import { AnneeCampagneResourceService } from "./resources/annee-campagne-resource.service";
import { AppComponent } from "../app.component";
import { ConfigAssetLoaderService } from "../configAssetLoader.service";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Meta, Title } from "@angular/platform-browser";
import { HtmlHead } from "../model/util.model";

@Injectable({
  providedIn: "root"
})
export class UtilService {

  constructor(private anneeCampagneResourceService: AnneeCampagneResourceService,
              private configAssetLoaderService: ConfigAssetLoaderService,
              private titleService: Title,
              private meta: Meta,
              private translateService: TranslateService,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  setCurrentYear(objetId?: number): Promise<void> {
    return new Promise<void>(resolve => {
      if (objetId) {
        this.anneeCampagneResourceService.getOpenedYears(objetId).subscribe({
          next: (res) => {
            if (!AppComponent.CURRENT_YEAR || !res.includes(AppComponent.CURRENT_YEAR)) {
              AppComponent.CURRENT_YEAR = res[res.length - 1];
            }
            resolve();
          }, error: () => resolve()
        });
      } else {
        this.anneeCampagneResourceService.getCurrentYear().subscribe({
          next: res => {
            AppComponent.CURRENT_YEAR = res;
            resolve();
          },
          error: () => resolve()
        });
      }
    });
  }

  setLanguage(language: string): Promise<void> {
    return new Promise(resolve => {
      // let defaultLang = navigator.language.startsWith('en-') ? LANGUE.EN.valueOf() : LANGUE.FR.valueOf();
      this.translateService.use(language).subscribe(() => {
        this.document.documentElement.lang = this.translateService.currentLang;
        //   this.cookieService.set(GOF_LANGUAGE, this.translateService.currentLang,
        //     {
        //       expires: EXPIRE_COOKIES,
        //       sameSite: "Strict"
        //     });
        resolve();
      });
    });

  }

  setHtmlHead(pageKey: string, descriptionKey: string, url: string, params: string): Promise<void> {
    return new Promise(resolve => {
      this.document.documentElement.lang = this.translateService.currentLang;
      this.translateService.get([descriptionKey, pageKey, "catalogue_des_formations", "universite_de_bordeaux"])
        .subscribe(translations => {
          this.setMetaTags({
              title: translations[pageKey],
              description: translations[descriptionKey],
              url,
              params
            },
            translations["catalogue_des_formations"],
            translations["universite_de_bordeaux"]);
        });
      resolve();
    });
  }

  private setMetaTags(htmlHead: HtmlHead, catalog: string, ub: string): void {
    this.titleService.setTitle(htmlHead.title + " - " + catalog);
    this.meta.updateTag({ name: "google-site-verification", content: "6_28O87u-4QojLLCxgMYUhfCCNMPL9kwW-4DWFrduuQ" });
    this.meta.updateTag({ property: "og:site_name", content: catalog + " - " + ub });
    this.meta.updateTag({ property: "og:title", content: htmlHead.title + " - " + catalog });
    this.meta.updateTag({ property: "og:description", content: htmlHead.description });
    this.meta.updateTag({ property: "og:image", content: "https://formations.u-bordeaux.fr/assets/_cache/search_block_1600x512_up/_files/ex_search-block.jpg" });
    this.meta.updateTag({ property: "og:type", content: "website" });
    this.meta.updateTag({ name: "twitter:card", content: "summary" });
    this.meta.updateTag({ name: "twitter:title", content: htmlHead.title + " - " + catalog });
    this.meta.updateTag({ name: "twitter:description", content: htmlHead.description });
    this.meta.updateTag({ name: "twitter:image", content: "https://formations.u-bordeaux.fr/assets/_cache/search_block_1600x512_up/_files/ex_search-block.jpg" });
    this.meta.updateTag({ name: "description", content: htmlHead.description });
    this.setUrlMetaTags(htmlHead);
  }

  setUrlMetaTags(htmlHead: HtmlHead) {
    this.configAssetLoaderService.loadConfigurations().subscribe(conf => {
      const url = conf.ihmUrl + "/#/" + htmlHead.url + htmlHead.params;
      this.meta.updateTag({ property: "og:url", content: url });
      this.meta.updateTag({ name: "twitter:url", content: url });
    });
  }

}
