import {Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, ViewEncapsulation} from "@angular/core";
import {
  PATH_DETAIL_FORMATION,
  PATH_PAR_COMPOSANTES,
  PATH_PAR_DIPLOMES,
  PATH_PAR_DISCIPLINES,
  PATH_MA_SELECTION,
  GOF_MA_SELECTION, GOF_LANGUAGE, GOF_PROFILE, GOF_CONTRAST, GOF_DYSLEXIC, EXPIRE_COOKIES, CLOSE_EVENT, GOF_INTERNATIONAL_CATALOGUE, GOF_ENSEIGNEMENT_INTERNATIONAL, DEFAULT_LANGUAGE
} from "../../constants/conf.constants";
import { GlobalStateService } from "../../services/global-state.service";
import { LANGUES, TYPES_OBJET } from "../../constants/utils.constants";
import { CookieService } from "ngx-cookie-service";
import { languesTrad, typesPublicTrad } from "../../constants/translationMaps/trad.constants";
import { RefTypePublic } from "../../constants/referentiel/ref.constants";
import {isPlatformBrowser} from "@angular/common";
import { UtilService } from "../../services/util.service";

@Component({
  selector: "app-header",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() currentProfile: string;
  @Input() dyslexicFriendlyToggler: boolean;
  @Input() highContrastToggler: boolean;
  @Input() isNavExpanded: boolean;

  isCatalogueInternational = false;
  isEnseignementInternational = false;

  isLangExpanded = false;
  isProfileExpanded = false;
  isAccessibilityExpanded = false;

  currentLanguage: string;

  pathDetailFormation = PATH_DETAIL_FORMATION;
  pathParDiplome = PATH_PAR_DIPLOMES;
  pathParDisciplines = PATH_PAR_DISCIPLINES;
  pathParComposante = PATH_PAR_COMPOSANTES;
  pathMaSelection = PATH_MA_SELECTION;

  typeObjet = TYPES_OBJET;
  languages = languesTrad;
  profiles = typesPublicTrad;
  selection: number = 0;

  constructor(private globalStateService: GlobalStateService,
              private cookieService: CookieService,
              private utilService: UtilService,
              @Inject(PLATFORM_ID) private platformId: Object) {
    if(this.cookieService.get(GOF_MA_SELECTION)){
      this.selection = JSON.parse(this.cookieService.get(GOF_MA_SELECTION)).length;
    }

    this.globalStateService.subscribe(GOF_INTERNATIONAL_CATALOGUE, res => {
      this.isCatalogueInternational = res;
      this.isCatalogueInternational ? this.currentLanguage = LANGUES.EN.valueOf() : this.currentLanguage = LANGUES.FR.valueOf();
      this.utilService.setLanguage(this.currentLanguage).then();
    });

    this.globalStateService.subscribe(GOF_ENSEIGNEMENT_INTERNATIONAL, res => {
      this.isEnseignementInternational = res;
      this.isEnseignementInternational ? this.currentLanguage = LANGUES.EN.valueOf() : this.currentLanguage = LANGUES.FR.valueOf();
      this.utilService.setLanguage(this.currentLanguage).then();
    });

    this.globalStateService.subscribe(GOF_MA_SELECTION, count => {
      this.selection = count;
    });

    this.globalStateService.subscribe(CLOSE_EVENT, () => {
      this.isLangExpanded = false;
      this.isProfileExpanded = false;
      this.isAccessibilityExpanded = false;
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isCatalogueInternational = JSON.parse(localStorage.getItem(GOF_INTERNATIONAL_CATALOGUE));
    }
    this.isCatalogueInternational ? this.currentLanguage = LANGUES.EN.valueOf() : this.currentLanguage = LANGUES.FR.valueOf();
    this.utilService.setLanguage(this.currentLanguage).then();
  }

  ngOnChanges(): void {
    this.setContrastStyle();
    this.setDyslexicStyle();
  }

  onHeaderNavToggle(): void {
    this.isNavExpanded = !this.isNavExpanded;
      if (this.isNavExpanded) {
        if (isPlatformBrowser(this.platformId)) {
          document.getElementById("gof").classList.add("header-nav-is-open");
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          document.getElementById("gof").classList.remove("header-nav-is-open");
        }
      }

  }

  onHeaderProfileToggle(): void {
    this.isProfileExpanded = !this.isProfileExpanded;
    this.isLangExpanded = false;
    this.isAccessibilityExpanded = false;
    this.isNavExpanded = false;
    if (this.isProfileExpanded) {
      if (isPlatformBrowser(this.platformId)) {
        document.getElementById("gof").classList.remove("header-nav-is-open");
        document.documentElement.classList.add("header-profile-nav-is-open");
        document.documentElement.classList.remove("header-accessibility-nav-is-open");
        document.documentElement.classList.remove("header-lang-nav-is-open");
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        document.documentElement.classList.remove("header-profile-nav-is-open");
      }
    }
  }

  onHeaderAccessibilityToggle(): void {
    this.isAccessibilityExpanded = !this.isAccessibilityExpanded;
    this.isLangExpanded = false;
    this.isProfileExpanded = false;
    this.isNavExpanded = false;
    if (this.isAccessibilityExpanded) {
      if (isPlatformBrowser(this.platformId)) {
        document.getElementById("gof").classList.remove("header-nav-is-open");
        document.documentElement.classList.remove("header-profile-nav-is-open");
        document.documentElement.classList.add("header-accessibility-nav-is-open");
        document.documentElement.classList.remove("header-lang-nav-is-open");
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        document.documentElement.classList.remove("header-accessibility-nav-is-open");
      }
    }
  }

  onHeaderLangToggle(): void {
    this.isLangExpanded = !this.isLangExpanded;
    this.isAccessibilityExpanded = false;
    this.isProfileExpanded = false;
    this.isNavExpanded = false;
    if (this.isLangExpanded) {
      if (isPlatformBrowser(this.platformId)) {
        document.getElementById("gof").classList.remove("header-nav-is-open");
        document.documentElement.classList.remove("header-profile-nav-is-open");
        document.documentElement.classList.remove("header-accessibility-nav-is-open");
        document.documentElement.classList.add("header-lang-nav-is-open");
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        document.documentElement.classList.remove("header-lang-nav-is-open");
      }
    }
  }

  closeHeaderNav(): void {
    this.isNavExpanded = false;
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById("gof").classList.remove("header-nav-is-open");
    }
  }

  switchToclassicCatalogue() {
    this.isEnseignementInternational = false;
    this.isCatalogueInternational = false;
    this.closeHeaderNav();
    this.setDefaultLanguage();
    this.setDefaultProfile();
  }

  switchLanguage(lang: string): void {
    this.utilService.setLanguage(lang)
      .then(() => {
        this.isLangExpanded = false;
        if (isPlatformBrowser(this.platformId)) {
          document.documentElement.classList.remove("header-lang-nav-is-open");
        }
        this.setDefaultProfile();
        this.currentLanguage = lang;
        this.globalStateService.notifyDataChanged(GOF_LANGUAGE, lang);
        // this.cookieService.set(GOF_LANGUAGE, lang);
      });
  }

  switchProfile(profile: string): void {
    window.location.reload();
    this.setDefaultLanguage();
    this.currentProfile = profile;
    this.cookieService.set(GOF_PROFILE, profile);
    this.globalStateService.notifyDataChanged(GOF_PROFILE, profile);
  }

  switchDyslexicFriendly(): void {
    this.dyslexicFriendlyToggler = !this.dyslexicFriendlyToggler;
    this.cookieService.set(GOF_DYSLEXIC, this.dyslexicFriendlyToggler ? "1" : "0",
      {
        expires: EXPIRE_COOKIES,
        sameSite: "Strict"
      });
    this.setDyslexicStyle();
  }

  switchHighContrast(): void {
    this.highContrastToggler = !this.highContrastToggler;
    this.cookieService.set(GOF_CONTRAST, this.highContrastToggler ? "1" : "0",
      {
        expires: EXPIRE_COOKIES,
        sameSite: "Strict"
      });
    this.setContrastStyle();
  }

  private setDefaultProfile(): void {
    this.currentProfile = RefTypePublic.DEFAULT;
    this.cookieService.set(GOF_PROFILE, this.currentProfile.toString());
    this.globalStateService.notifyDataChanged(GOF_PROFILE, this.currentProfile.toString());
  }

  private setDefaultLanguage(): void {
    this.utilService.setLanguage(DEFAULT_LANGUAGE).then(() => {
      this.currentLanguage = DEFAULT_LANGUAGE;
      this.globalStateService.notifyDataChanged(GOF_LANGUAGE, this.currentLanguage);
    });
  }

  private setDyslexicStyle() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById("dyslexic-friendly").setAttribute("aria-pressed", this.dyslexicFriendlyToggler ? "true" : "false");
      document.documentElement.classList.toggle("dyslexic-friendly", this.dyslexicFriendlyToggler);
    }
  }

  private setContrastStyle() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById("high-contrast").setAttribute("aria-pressed", this.highContrastToggler ? "true" : "false");
      document.documentElement.classList.toggle("high-contrast", this.highContrastToggler);
    }
  }

}
