import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from "@angular/core";
import { EditoService } from "../../services/edito.service";
import { SafeHtml } from "@angular/platform-browser";
import {BREADCRUMB_THREAD, GOF_FILTERS, GOF_LANGUAGE, GOF_SEARCH, PATH_ACCUEIL} from "../../constants/conf.constants";
import { GlobalStateService } from "../../services/global-state.service";
import { UtilService } from "../../services/util.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "app-accueil",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"]
})
export class AccueilComponent implements OnInit {

  editoHtml: SafeHtml;

  constructor(private utilService: UtilService,
              private globalStateService: GlobalStateService,
              private editoService: EditoService,
              @Inject(PLATFORM_ID) private platformId: Object) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.accueil", "universite_de_bordeaux_description", PATH_ACCUEIL, "").then();
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.accueil", "universite_de_bordeaux_description", PATH_ACCUEIL, "").then();

    this.editoService.gethtml().subscribe(res => {
      this.editoHtml = res;
    });

    // init Storage datas
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(GOF_SEARCH, JSON.stringify([]));
      sessionStorage.removeItem(GOF_FILTERS);
      sessionStorage.setItem(BREADCRUMB_THREAD, JSON.stringify([]));
    }
  }
}

