import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Menu, PathItem } from "../../model/util.model";
import { GOF_LANGUAGE, PATH_ACCUEIL, PATH_DETAIL_FORMATION, PATH_PAR_DIPLOMES } from "../../constants/conf.constants";
import { ParDiplomeService } from "../../services/par-diplome.service";
import { LazyLoadService } from "../../services/lazy-load.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../../app.component";
import { AbstractDocument } from "../../model/esDoc.model";
import { UtilService } from "../../services/util.service";
import { GlobalStateService } from "../../services/global-state.service";

@Component({
  selector: "app-par-diplomes",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./par-diplomes.component.html",
  styleUrls: ["./par-diplomes.component.scss"]
})
export class ParDiplomesComponent implements OnInit {

  thread: PathItem[];
  pathDetailFormation = PATH_DETAIL_FORMATION;

  menu: Menu[];

  constructor(private parDiplomeService: ParDiplomeService,
              private lazyLoadScriptServiceService: LazyLoadService,
              private translateService: TranslateService,
              private globalStateService: GlobalStateService,
              private utilService: UtilService) {
    this.globalStateService.subscribe(GOF_LANGUAGE, () => {
      this.utilService.setHtmlHead("page.par_diplome", "universite_de_bordeaux_description", PATH_PAR_DIPLOMES, "").then();
    });
  }

  ngOnInit(): void {
    this.utilService.setHtmlHead("page.par_diplome", "universite_de_bordeaux_description", PATH_PAR_DIPLOMES, "").then();

    this.thread = [
      new PathItem("page.accueil", [PATH_ACCUEIL]),
      new PathItem("page.par_diplome", [PATH_PAR_DIPLOMES])
    ];

    this.parDiplomeService.getNiveau1()
      .subscribe(res => {
        this.menu = res;
        this.lazyLoadScriptServiceService.injectScript("/assets/js/accordion.js");
      });
  }

  getNiveau2(menu: Menu): void {
    this.parDiplomeService.getNiveau2(menu.intitule)
      .subscribe(res => {
        menu.sousmenus = res;
        this.lazyLoadScriptServiceService.injectScript("/assets/js/accordion.js");
      });
  }

  getNiveau3(typeFormation: string, menu: Menu): void {
    this.parDiplomeService.getNiveau3(typeFormation, menu.intitule)
      .subscribe(res => menu.formationDocs = res);
  }

  onSelected(formation: AbstractDocument): void {
    AppComponent.CURRENT_YEAR = formation.annee;
  }
}
