import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from "@angular/core";
import pack from "./../../../../package.json";
import {RgpdDialogComponent} from "../../components/rgpd/rgpd-dialog/rgpd-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {GOF_INTERNATIONAL_CATALOGUE, GOF_LANGUAGE, PATH_PLAN_DU_SITE} from "../../constants/conf.constants";
import {LANGUES} from "../../constants/utils.constants";
import {GlobalStateService} from "../../services/global-state.service";
import {MatDialog} from '@angular/material/dialog';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "app-footer",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {

  version: string;
  univHomeLink: string;
  accessibliteUrl: string;
  donneesPersonnellesUrl: string;
  mentionsLegalesUrl: string;

  pathPlanDuSite = PATH_PLAN_DU_SITE;

  constructor(private translateService: TranslateService,
              private globalStateService: GlobalStateService,
              private matDialog: MatDialog,
              @Inject(PLATFORM_ID) private platformId: Object) {

    this.translateService.get(["page.accueil", "universite_de_bordeaux"]).subscribe(translations => {
      this.univHomeLink = translations["page.accueil"] + " - " + translations["universite_de_bordeaux"];
    });

    this.globalStateService.subscribe(GOF_LANGUAGE, currentLang => currentLang === LANGUES.FR ? this.setFrenchUrls() : this.setEnglishUrls());
  }

  ngOnInit(): void {
    this.version = pack.version;
    this.setFrenchUrls();
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(GOF_INTERNATIONAL_CATALOGUE)) {
        this.setEnglishUrls();
      }
    }
  }

  openCookiesManagement(): void {
    this.matDialog.open(RgpdDialogComponent, {
      maxWidth: "850px",
      minWidth: "320px"
    });
  }

  private setEnglishUrls(): void {
    this.accessibliteUrl = "https://www.u-bordeaux.fr/en/accessibility";
    this.donneesPersonnellesUrl = "https://www.u-bordeaux.fr/en/privacy-policy";
    this.mentionsLegalesUrl = "https://www.u-bordeaux.fr/en/credits-legal-notice";
  }


  private setFrenchUrls(): void {
    this.accessibliteUrl = "https://www.u-bordeaux.fr/accessibilite";
    this.donneesPersonnellesUrl = "https://www.u-bordeaux.fr/donnees-personnelles";
    this.mentionsLegalesUrl = "https://www.u-bordeaux.fr/mentions-legales";
  }

}
